import SocketIO from 'socket.io-client';
import { SOCKET_URL, isProduction } from '@/config';

const reconnectingOptions = {
  reconnectionDelay: 1000,
  reconnectionDelayMax: 20 * 1000,
  randomizationFactor: 0.5,
};

export const socketIoInstance = isProduction
  ? SocketIO(SOCKET_URL, reconnectingOptions)
  : SocketIO('/', {
      path: SOCKET_URL,
      ...reconnectingOptions,
    });

export const subscribeOnEvent = (eventName, callback) => {
  socketIoInstance.on(eventName, callback);

  return () => {
    socketIoInstance.off(eventName, callback);
  };
};
