import Provider from '@provider'
import { RegistrationModel } from '@/models'
import {format} from "date-fns";

export default {
  exportRegistration: ({
    projectIds, // Project Ids,
    userIds, // User Ids,
    dateFrom, // date_format:Y-m-d', 
    dateTill, // date_format:Y-m-d
    registrationTypeIds, // Registration types ids
    type // standard | syntess | afas | proteus | unidis
  }) => {
    const isUnidis = type === 'unidis';
    const responseType = isUnidis ? 'json' : 'blob';

    return new Promise((resolve, reject) => {
      Provider.api
        .post(`/registrations/export/` + type, {
          project_ids: projectIds,
          user_ids: userIds,
          registration_type_ids: registrationTypeIds,
          date_from: dateFrom,
          date_till: dateTill
        },
          {
            responseType,
            headers: {
              'Content-Type': 'application/json'
            }
          }
        )
        .then(
          response => {
            if (isUnidis) {
              resolve(response.data);
              return;
            }

            let extension = 'xlsx'
            if (type === 'syntess') {
                extension = 'xls'
            } else if (type === 'proteus') {
                extension = 'csv'
            }
            const blob = response.data
            const link = document.createElement('a')
            link.href = window.URL.createObjectURL(blob)
            link.download = 'Woub uren export ' + type + ' ' + format(new Date(), "yyyy-MM-dd HH:mm") + '.' + extension
            link.click()
            link.remove()

            resolve();
          }

        )
        .catch(reject)
    })
  },


  addRegistration: ({
    registrationTypeId,
    projectId,
    userId,
    startAt,
    endAt,
    amount,
    date,
    comment,
  }) => {
    return new Promise((resolve, reject) => {
      Provider.api
        .post(`/registrations/`, {
          registration_type_id: registrationTypeId,
          project_id: projectId,
          user_id: userId,
          start_at: startAt,
          end_at: endAt,
          amount,
          date,
          comment,
        })
        .then(response => resolve(new RegistrationModel(response.data)))
        .catch(reject)
    })
  },

  getRegistrations: ({
    registrationType,
    projectId,
    userId,
    userIds,
    projectIds,
    typeIds,
    dateFrom,
    dateTill,
    cancelToken,
  } = {}) => {
    return new Promise((resolve, reject) => {
      Provider.api
        .post(`/registrations/report`, {
          registration_type: registrationType,
          project_id: projectId,
          user_id: userId,
          date_from: dateFrom,
          date_till: dateTill,
          user_ids: userIds,
          project_ids: projectIds,
          type_ids: typeIds,
        }, {
          cancelToken,
        })
        .then(response =>
          resolve(response.data.map(e => new RegistrationModel(e)))
        )
        .catch(reject)
    })
  },

  getRegistration: ({ id }) => {
    return new Promise((resolve, reject) => {
      Provider.api
        .get(`/registrations/${id}`)
        .then(response => resolve(new RegistrationModel(response.data)))
        .catch(reject)
    })
  },

  updateRegistration: ({
    id,
    registrationTypeId,
    projectId,
    userId,
    startAt,
    endAt,
    amount,
    date,
    comment,
  }) => {
    return new Promise((resolve, reject) => {
      Provider.api
        .put(`/registrations/${id}`, {
          registration_type_id: registrationTypeId,
          project_id: projectId,
          user_id: userId,
          start_at: startAt,
          end_at: endAt,
          amount,
          date,
          comment,
        })
        .then(response => resolve(new RegistrationModel(response.data)))
        .catch(reject)
    })
  },

  deleteRegistration: ({ id }) => {
    return new Promise((resolve, reject) => {
      Provider.api
        .delete(`/registrations/${id}`)
        .then(resolve)
        .catch(reject)
    })
  },
}
