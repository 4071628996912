import _ from 'lodash'

import Model from '@/models/Model'
import UserModel from '@/models/User'
import ProjectModel from '@/models/Project'
import ChatModel from '@/models/Chat'

const RAW_NOTIFICATION_TYPES = Object.freeze({
  EXPORT_DONE: 'App\\Notifications\\ExportDoneNotification',
  EXPORT_FAILED: 'App\\Notifications\\ExportFailedNotification',
});

const TYPES_NOTIFICATION = {
  checklistItemStatusChanged: 'ChecklistItemStatusChanged',
  ChecklistItemAssigned: 'ChecklistItemAssigned',
  checklistSigned: 'ChecklistSigned',
  commentAdded: 'CommentAdded',
  projectDeadlineReminder: 'ProjectDeadlineReminder',
  fillHoursReminder: 'fillHoursReminder',
  remoteOnlySyncCompleted: 'remoteOnlySyncCompleted',
  exportDone: 'exportDone',
  exportFailed: 'exportFailed',
};

const resolveType = rawData => {
  const { data, type } = rawData;
  return type === RAW_NOTIFICATION_TYPES.EXPORT_DONE
    ? TYPES_NOTIFICATION.exportDone
    : type === RAW_NOTIFICATION_TYPES.EXPORT_FAILED
    ? TYPES_NOTIFICATION.exportFailed
    : TYPES_NOTIFICATION[data.type];
};

class NotificationModel extends Model {
  constructor(data) {
    super(data)

    this.id = data.id

    this.type = resolveType(data)

    this.readAtM = data.read_at
      ? this.toMomentDate(data.read_at)
      : undefined
    this.updatedAtM = this.toMomentDate(data.updated_at_utc)

    // NOTE: the created_at_utc property is null in old notifications
    // change this logic after the database migration
    // or after a long time after the release of the feature
    this.createdAtM = data.created_at_utc
      ? this.toMomentDate(data.created_at_utc)
      : this.toMomentDate(data.created_at);

    this.data = {}

    // INIT DATA NOTIFICATIONS
    switch (this.type) {
      case TYPES_NOTIFICATION.ChecklistItemAssigned:
        this.user = ((e = _.get(data, 'data.changedBy')) =>
          e ? new UserModel(e) : undefined)()
        this.checklistId = _.get(data, 'data.checklistId', null)
        this.checklistItemName = _.get(data, 'data.checklistItemName', '')
        this.checklistItemId = _.get(data, 'data.checklistItemId', null)
        this.startAt = data.data?.start_at
          ? this.toMomentDate(data.data.start_at)
          : null
        this.endAt = data.data?.end_at
          ? this.toMomentDate(data.data.end_at)
          : null
        this.isDeleted = data.data.is_deleted || false;

        break

      case TYPES_NOTIFICATION.checklistItemStatusChanged:
        this.user = ((e = _.get(data, 'data.changedBy')) =>
          e ? new UserModel(e) : undefined)()

        this.checked = Boolean(_.get(data, 'data.checklistItemStatus', false))
        this.checklistItemName = _.get(data, 'data.checklistItemName', '')
        this.checklistId = _.get(data, 'data.checklistId', null)
        this.checklistItemId = _.get(data, 'data.checklistItemId', null)

        this.project = ((e = _.get(data, 'data.project')) =>
          e ? new UserModel(e) : undefined)()

        this.chat = ((e = _.get(data, 'data.chat')) =>
          e ? new ChatModel(e) : undefined)()

        break

      case TYPES_NOTIFICATION.checklistSigned:
        this.checklistName = _.get(data, 'data.checklistName', '')
        this.projectId = _.get(data, 'data.projectId', null)
        this.projectName = _.get(data, 'data.projectName', '')
        this.userFirstName = _.get(data, 'data.userFirstName', '');
        this.user = _.get(data, 'data.changedBy')
          ? new UserModel(_.get(data, 'data.changedBy'))
          : undefined;

        break

      case TYPES_NOTIFICATION.commentAdded:
        this.user = ((e = _.get(data, 'data.createdBy')) =>
          e ? new UserModel(e) : undefined)()

        this.checklistItemName = _.get(data, 'data.checklistItemName', '')
        this.checklistId = _.get(data, 'data.checklistId', null)
        this.checklistItemId = _.get(data, 'data.checklistItemId', null)
        this.commentId = _.get(data, 'data.commentId', null);

        this.project = ((e = _.get(data, 'data.project')) =>
          e ? new UserModel(e) : undefined)()

        this.chat = ((e = _.get(data, 'data.chat')) =>
          e ? new ChatModel(e) : undefined)()

        break

      case TYPES_NOTIFICATION.projectDeadlineReminder:
        this.project = ((e = _.get(data, 'data.project')) =>
          e ? new UserModel(e) : undefined)()

        break

      case TYPES_NOTIFICATION.remoteOnlySyncCompleted:
        this.integrationSlug = data.data?.integration?.slug;

        break;

      case TYPES_NOTIFICATION.exportDone:
        this.exportLog = data.data;

        break;

      case TYPES_NOTIFICATION.exportFailed:
        this.exportLog = data.data;

        break;
    }
  }
}

export default NotificationModel
